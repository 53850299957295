import { Badge } from "react-bootstrap"

interface BadgeProps {
  state: string
}

export const StateBadge = ({state}: BadgeProps) => {
  const getColor = () => {
    switch (state) {
      case 'submitted':
        return 'primary'
      case 'paid':
        return 'success'
      case 'denied':
        return 'danger'
      default:
        return 'secondary'
    }
  }

  return (
    <Badge bg={getColor()}>{state}</Badge>
  )
}