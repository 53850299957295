import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Payout, SDEType } from "../types";
import SDEAutocomplete from "./SDEAutocomplete";

interface Props {
  payout: Payout | null;
  show: boolean;
  onClose: (save: boolean, ship_type: number, base_amount: number, id: number | null | undefined) => void;
}

export default function PayoutModal({payout, show, onClose}: Props) {
  const [shipType, setShipType] = useState<SDEType>({
    id: 0,
    name: ''
  });
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    setShipType({
      id: payout?.ship_type_id || 0,
      name: payout?.ship_type_name || ''
    })
    setAmount(payout?.base_amount || 0)
  }, [payout])

  return (
    <>
    <Modal show={show} onHide={() => onClose(false, shipType.id, amount, payout?.ID)} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{payout?.ID ? 'Edit Payout' : 'Create Payout'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Ship Type</Form.Label>
            <SDEAutocomplete type={shipType} onSelect={setShipType} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Payout Amount</Form.Label>
            <Form.Control type="number" value={amount} step={1000} onChange={(e) => setAmount(parseInt(e.target.value))} placeholder="" />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose(false, shipType.id, amount, payout?.ID)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onClose(true, shipType.id, amount, payout?.ID)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}