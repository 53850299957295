import { Row } from "react-bootstrap";
import KillSearch from "../components/KillSearch";

export default function AdminKills() {
  return (
    <>
      <Row>
        <h1>Kill Search</h1>
      </Row>
      <KillSearch />
    </>
  )
}