import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { hasSession } from '../utils';
import type { RootState } from './store'

// Define a type for the slice state
interface UserState {
  role: number;
  version: string;
}

// Define the initial state using that type
let initialState = {
  role: 0,
  version: 'v1',
} as UserState

try {
  const data = localStorage.getItem('user_store')
  if (data) {
    initialState = JSON.parse(data) as UserState
  }
} catch {}

const saveStore = (state: UserState) => {
  try {
    localStorage.setItem('user_store', JSON.stringify(state));
  } catch {}
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRole: (state: UserState, action: PayloadAction<number>) => {
      state.role = action.payload
      saveStore(state)
    },
  },
})

export const { setRole } = userSlice.actions

export const getRole = (state: RootState) => state.user.role
export const isLeader = (state: RootState) => state.user.role === 2
export const isMember = (state: RootState) => state.user.role >= 1
export const isLoggedIn = (state: RootState) => {
  return state.user.role > 0 && hasSession()
}

export default userSlice.reducer