import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { addNotification } from "../redux/flash";
import { useAppDispatch } from "../redux/hooks";
import { Payout } from "../types";
import { doFetch, formatCurrency, formatTime } from "../utils";

interface Props {
  ship_type_id: number;
}

export default function PayoutCard({ship_type_id}: Props) {
  const dispatch = useAppDispatch()
  const [fetched, setFetched] = useState(false)
  const [payout, setPayout] = useState<Payout | null>(null);

  useEffect(() => {
    doFetch(`/api/payouts/${ship_type_id}`)
    .then(resp => {
      if (!resp.ok && resp.status !== 404) {
        dispatch(
          addNotification({
            type: "danger",
            text: "Unable perform search",
            dismissTime: 2,
          })
        );
        throw new Error('invalid response')
      }

      if (resp.status === 404) {
        setFetched(true)
        throw new Error("404")
      }

      return resp.json()
    })
    .then(data => {
      setFetched(true)
      if (data) {
        setPayout(data!)
      }
    })
    .catch(err => {
      console.error(err)
    })
  }, [ship_type_id, dispatch])

  if (!payout) {
    if (fetched) {
      return <p className="lead">No payout found for this ship</p>
    }
    return <p className="lead">Loading...</p>
  }

  return (
    <Card>
      <Card.Header as="h5">Payout for {payout.ship_type_name}</Card.Header>
      <Card.Body>
        <Card.Text>
          Payout Amount: <b>{formatCurrency(payout.base_amount)}</b><br />
          Updated At: <b>{formatTime(payout.UpdatedAt)}</b>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}