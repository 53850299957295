import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

interface Props {
  show: boolean
  onClose: (save: boolean, link: string, description: string) => void
}

export default function SubmitKill({show, onClose}: Props) {
  const [link, setLink] = useState('')
  const [description, setDescription] = useState('')

  return (
    <>
    <Modal show={show} onHide={() => onClose(false, link, description)} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Submit Killmail</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>ESI Link*</Form.Label>
            <Form.Control required type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder="https://esi.evetech.net/latest/killmails/98243204/636e7e8a4beabece117f43467ad2ee59ff34dfba/" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="" />
            <Form.Text muted>
              Any additional information you would like the SRPer to know.
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose(false, link, description)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onClose(true, link, description)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}