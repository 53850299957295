import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { addNotification } from "../redux/flash";
import { useAppDispatch } from "../redux/hooks";
import { Kill } from "../types";
import { doFetch } from "../utils";
import Killcard from "./Killcard";
import SubmitKill from "./SubmitKill";

export default function Killmails() {
  const dispatch = useAppDispatch()
  const [kills, setKills] = useState<Kill[]>([]);

  const fetchKills = () => {
    doFetch('/api/kills')
    .then(resp => {
      if (!resp.ok) {
        throw new Error('invalid response')
      }

      return resp.json()
    }).then(data => {
      setKills(data)
    })
  }

  useEffect(() => {
    fetchKills()
  }, [])

  const [show, setShow] = useState(false)
  const onSubmitClose = (save: boolean, link: string, description: string) => {
    setShow(false)

    if (save) {
      doFetch(`/api/kills`, {
        method: 'POST',
        body: JSON.stringify({
          link: link,
          description: description
        })
      }).then(resp => {
        if (!resp.ok) {
          if (resp.status === 422) {
            dispatch(
              addNotification({
                type: "danger",
                text: "Killmail was already submitted",
                dismissTime: 0
              })
            );
          }

          throw new Error('invalid response')
        }

        fetchKills()
      })
    }
  }

  const killcards = kills.map((k, i) => {
    return (
      <Col xs={12} md={4} key={i}>
        <Killcard key={i} kill={k} />
      </Col>
    )
  })

  return (
    <>
      <Row>
        <Col md={12} className="d-flex">
          <h1>Your Losses</h1>
          <Button variant="primary" className="ms-auto" onClick={() => setShow(true)}>Submit Loss</Button>
        </Col>

        {killcards}

        <SubmitKill show={show} onClose={onSubmitClose} />
      </Row>
    </>
  )
}