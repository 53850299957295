import { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Killcard from "../components/Killcard";
import PayoutCard from "../components/PayoutCard";
import { addNotification } from "../redux/flash";
import { useAppDispatch } from "../redux/hooks";
import { Kill } from "../types";
import { doFetch } from "../utils";

export default function AdminKillDetails() {
  const { id } = useParams();
  const dispatch = useAppDispatch()
  const [kill, setKill] = useState<Kill | null>(null);
  const [notes, setNotes] = useState('')

  const fetchKill = useCallback(() => {
    doFetch(`/api/admin/kills/${id}`)
    .then(resp => {
      if (!resp.ok) {
        throw new Error('invalid response')
      }

      return resp.json()
    })
    .then(data => {
      setKill(data)
      setNotes(data.leadership_note)
    })
  }, [id])

  useEffect(() => {
    fetchKill()
  }, [id, fetchKill])

  if (!kill) {
    return <h3>Loading...</h3>
  }

  const setState = (state: string) => {
    doFetch(`/api/admin/kills/${kill.ID}/state`, {
      method: 'POST',
      body: JSON.stringify({
        state
      })
    })
    .then(resp => {
      if (resp.ok) {
        fetchKill()
      }
    })
  }

  const saveNotes = () => {
    doFetch(`/api/admin/kills/${kill.ID}/notes`, {
      method: 'POST',
      body: JSON.stringify({
        notes
      })
    })
    .then(resp => {
      if (resp.ok) {
        dispatch(addNotification({
          type: 'success',
          text: 'Notes saved',
          dismissTime: 2
        }))
      }
    })
  }

  const actions = []
  switch (kill.state) {
    case 'submitted':
      actions.push(<Button key={actions.length} variant="success" onClick={() => setState('paid')}>Mark Paid</Button>)
      actions.push(<Button key={actions.length} variant="danger" onClick={() => setState('denied')}>Deny</Button>)
      break
    case 'denied':
      actions.push(<Button key={actions.length} variant="success" onClick={() => setState('paid')}>Mark Paid</Button>)
      actions.push(<Button key={actions.length} variant="primary" onClick={() => setState('submitted')}>Mark Submitted</Button>)
      break
    case 'paid':
      actions.push(<Button key={actions.length} variant="danger" onClick={() => setState('denied')}>Deny</Button>)
      actions.push(<Button key={actions.length} variant="primary" onClick={() => setState('submitted')}>Mark Submitted</Button>)
      break
  }

  return (
    <>
      <Row>
        <Col>
          <h1>Kill Details</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Killcard kill={kill} />
        </Col>
        <Col>
          <h2>Leadership Section</h2>
          <Row>
            <Form>
              <Form.Group>
                <Form.Label>Notes</Form.Label>
                <Form.Control type="text" as="textarea" value={notes} onChange={(e) => setNotes(e.target.value)} />
              </Form.Group>
              <Button variant="primary" onClick={saveNotes}>Save Notes</Button>
            </Form>
          </Row>
          <Row>
            <h2>Payout Details</h2>
            <PayoutCard ship_type_id={kill.ship_type_id} />
          </Row>
        </Col>
        <Col>
          <h2>Actions</h2>
          <ButtonGroup vertical>
            {actions}
          </ButtonGroup>
        </Col>
      </Row>
    </>
  )
}