import { useCallback, useEffect, useState } from "react";
import { Table, Image, Button, ButtonGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { addNotification } from "../redux/flash";
import { useAppDispatch } from "../redux/hooks";
import { isLeader } from "../redux/user";
import { PageResult, Payout } from "../types";
import { doFetch, formatCurrency, formatTime, savePayout } from "../utils";
import PayoutModal from "./PayoutModal";

export default function PayoutsTable() {
  const dispatch = useAppDispatch()
  const [page, setPage] = useState<PageResult<Payout>>({results: [], count: 0, per_page: 0});
  const [editedPayout, setEditedPayout] = useState<Payout | null>(null);
  const leader = useSelector(isLeader)

  const getData = useCallback(() => {
    doFetch(`/api/payouts`)
    .then(resp => {
      if (!resp.ok) {
        dispatch(addNotification({
          type: "danger",
          text: "Unable retrieve payouts",
          dismissTime: 2,
        }))
      }

      return resp.json()
    }).then(data => {
      setPage(data)
    })
  }, [dispatch]);

  useEffect(() => {
    getData()
  }, [getData])

  const onSubmitPayout = (save: boolean, ship_type: number, base_amount: number, id: number | null | undefined) => {
    setEditedPayout(null);

    if (!save || ship_type === 0 || base_amount === 0) {
      return
    }

    savePayout(ship_type, base_amount, id, dispatch);
  }

  const deletePayout = (p: Payout) => {
    if (window.confirm("Are you sure?")) {
      doFetch(`/api/admin/payouts/${p.ID}`, {
        method: 'DELETE',
      }).then(_ => {
        document.location.reload();
      })
    }
  }

  const rows =  page.results.map((r, i) => {
    const actions = (
      <ButtonGroup>
        <Button variant="info" onClick={() => setEditedPayout(r)}>Edit</Button>
        <Button variant="danger" onClick={() => deletePayout(r)}>Delete</Button>
      </ButtonGroup>
    )

    return <tr key={i}>
      <td><Image
          src={`https://images.evetech.net/types/${r.ship_type_id}/icon?size=64`}
        ></Image></td>
      <td>{r.ship_type_name}</td>
      <td>{formatCurrency(r.base_amount)}</td>
      <td>{formatTime(r.UpdatedAt)}</td>
      {leader ?
        <td>
          {actions}
        </td>
      : null}
    </tr>
  })

  return (
    <>
      <PayoutModal onClose={onSubmitPayout} payout={editedPayout} show={!!editedPayout} />
      <Table striped bordered hover>
        <thead>
            <tr>
              <td></td>
              <td>Ship</td>
              <td>Payout Amount</td>
              <td>Updated At</td>
              {leader ? <td>Actions</td> : null}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
      </Table>
    </>
  )
}