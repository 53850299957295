import { useCallback, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { removeNotification } from "../redux/flash";
import { useAppDispatch } from "../redux/hooks";
import { Notification } from '../types';

interface Props {
  notification: Notification
}

export default function NotificationAlert({notification}: Props) {
  const [show, setShow] = useState(true)
  const dispatch = useAppDispatch()

  const dismiss = useCallback(() => {
    setShow(false)

    setTimeout(() => {
      dispatch(removeNotification(notification))
    }, 300)
  }, [dispatch, notification])

  useEffect(() => {
    if (notification.dismissTime > 0) {
      setTimeout(() => {
        dismiss()
      }, notification.dismissTime * 1000)
    }
  }, [notification.dismissTime, dismiss])

  const onClose = () => {
    dismiss()
  }

  return (
    <Alert show={show} onClose={onClose} variant={notification.type} dismissible>
      {notification.text}
    </Alert>
  )
}