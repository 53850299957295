import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Home from "./routes/Home";
import Auth from "./routes/Auth";
import Login from "./routes/Login";
import Nav from "./components/Nav";
import Payouts from "./routes/Payouts";
import AdminKills from "./routes/AdminKills";
import AdminKillDetails from "./routes/AdminKillDetails";
import NotificationList from "./components/NotificationList";
import AdminRoute from "./components/AdminRoute";

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Container>
        <main>
          <NotificationList />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/auth" element={<Auth />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/payouts" element={<Payouts />}></Route>
            <Route path="/admin/kills/:id" element={
              <AdminRoute>
                <AdminKillDetails/>
              </AdminRoute>
            }></Route>
            <Route path="/admin/kills" element={
              <AdminRoute>
                <AdminKills />
              </AdminRoute>
            }></Route>
          </Routes>
        </main>
      </Container>
    </BrowserRouter>
  );
}

export default App;
