import { Card, Image, ListGroup } from "react-bootstrap";
import { Kill } from "../types";
import { formatTime } from "../utils";
import { StateBadge } from "./component-utils";

interface Props {
  kill: Kill;
}

export default function Killcard({kill}: Props) {
  const zkillLink = `https://zkillboard.com/kill/${kill.killmail_id}`
  return (
  <Card>
    <Card.Header as="h5">{kill.ship_name} in {kill.solar_system_name}</Card.Header>
    <Card.Body>
      <Card.Title>
        <Image
          src={`https://images.evetech.net/types/${kill.ship_type_id}/icon?size=64`}
        ></Image>
      </Card.Title>
      <Card.Text>
        State: <b><StateBadge state={kill.state} /></b><br />
        Death Time: <b>{formatTime(kill.kill_time)}</b>
      </Card.Text>
      <div className="dropdown-divider"></div>
      <p>
        <b>User Notes: </b> <em>{kill.description}</em><br />
      </p>
      <div className="dropdown-divider"></div>
      {!!kill.leadership_note ? <p>
        <b>Leadership Notes: </b> <em>{kill.leadership_note}</em>
      </p> : null}

    </Card.Body>
    <ListGroup className="list-group-flush">
      <ListGroup.Item>
        <a target="_blank" rel="noreferrer" href={zkillLink}>{zkillLink}</a>
      </ListGroup.Item>
    </ListGroup>
    <Card.Footer>
      Submitted on {formatTime(kill.CreatedAt)}
    </Card.Footer>
  </Card>
  )
}