import { useCallback, useEffect, useState } from "react";
import { Row, Col, Table, Image, Form, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { addNotification } from "../redux/flash";
import { useAppDispatch } from "../redux/hooks";
import { Kill, PageResult } from "../types";
import { doFetch, formatTime } from "../utils";
import { StateBadge } from "./component-utils";

export default function KillSearch() {
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState('')
  const [search, setSearch] = useState<PageResult<Kill>>({results: [], count: 0, per_page: 0})

  const doSearch = useCallback(() => {
    doFetch(`/api/admin/kills?filter=${filter}`)
    .then(resp => {
      if (!resp.ok) {
        dispatch(
          addNotification({
            type: "danger",
            text: "Unable perform search",
            dismissTime: 2,
          })
        );
        throw new Error('invalid response')
      }

      return resp.json()
    }).then(data => {
      setSearch(data)
    })
  }, [filter, dispatch])

  useEffect(() => {
    doSearch()
  }, [doSearch, filter])

  const rows = search.results.map((k, i) => {
    return <tr key={i}>
      <td><Image
          src={`https://images.evetech.net/types/${k.ship_type_id}/icon?size=64`}
        ></Image></td>
      <td>{k.user?.username}</td>
      <td>{k.ship_name}</td>
      <td><StateBadge state={k.state} /></td>
      <td>{k.solar_system_name}</td>
      <td>{formatTime(k.kill_time)}</td>
      <td>
        <Link to={`/admin/kills/${k.ID}`} className="btn btn-info">
          View
        </Link>
      </td>
    </tr>
  })

  return (
    <>
      <Row>
        <Form.Label column="sm" lg={1}>
          Filter
        </Form.Label>
        <Col>
          <Form.Select onChange={(e) => setFilter(e.target.value)}>
            <option value="">All</option>
            <option value="submitted">Submitted</option>
            <option value="paid">Paid</option>
            <option value="denied">Denied</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <td></td>
                <td>User</td>
                <td>Ship Type</td>
                <td>Status</td>
                <td>System</td>
                <td>Death At</td>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}
