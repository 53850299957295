import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { isLeader } from '../redux/user'

export default function AdminRoute({children}:  { children: JSX.Element }) {
  const location = useLocation();
  const leader = useAppSelector(isLeader)

  if (!leader) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}