import React from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import FatBee from "../img/recon.png";

function getRedirect() {
  return window.location.hostname.includes("localhost")
    ? "http://localhost:3001/api/oauth/begin"
    : "/api/oauth/begin";
}

export default function Login() {
  return (
    <Row>
      <Col className="text-center" xs={{ span: 4, offset: 4 }}>
        <Image className="logo" src={FatBee} />
        <h1>Recon SRP</h1>
        <Button variant="primary" href={getRedirect()}>
          Login
        </Button>
      </Col>
    </Row>
  );
}
