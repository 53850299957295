import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import PayoutModal from "../components/PayoutModal";
import PayoutsTable from "../components/PayoutsTable";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { isLeader } from "../redux/user";
import { savePayout } from "../utils";

export default function Payouts() {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const leader = useAppSelector(isLeader);

  const onSubmitPayout = (save: boolean, ship_type: number, base_amount: number, id: number | null | undefined) => {
    setShow(false);

    if (!save || ship_type === 0 || base_amount === 0) {
      return
    }

    savePayout(ship_type, base_amount, id, dispatch);
  }

  return (
    <>
      <Row>
        <Col md={12} className="d-flex">
          <h1>Payouts</h1>
          {leader ? <Button variant="primary" className="ms-auto" onClick={() => setShow(true)}>Create Payout</Button> : null}
          <PayoutModal show={show} payout={null} onClose={onSubmitPayout} />
        </Col>
      </Row>
      <Row>
        <Col>
          <PayoutsTable />
        </Col>
      </Row>
    </>
  )
}
