import { Col, Row } from "react-bootstrap";
import { useAppSelector } from '../redux/hooks'
import { getNotifications } from "../redux/flash";
import NotificationAlert from "./NotificationAlert";

export default function NotificationList() {
  const notifications = useAppSelector(getNotifications)

  const alerts = notifications.map((n, i) => {
    return <NotificationAlert key={i} notification={n} />
  })

  return (
    <Row>
      <Col>
        {alerts}
      </Col>
    </Row>
  )
}
