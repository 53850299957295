import { useEffect } from "react"
import { clearRole, doFetch } from "../utils"

import { useNavigate } from "react-router-dom";
import { setRole } from "../redux/user";
import { useDispatch } from "react-redux";

export default function Auth() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    clearRole();
    doFetch(`/api/permissions`)
    .then(resp => {
      if (!resp.ok) {
        throw new Error('invalid login')
      }

      return resp.json()
    })
    .then((data: any) => {
      dispatch(setRole(parseInt(data.role)))
      navigate('/')
    })
    .catch(() => {
      navigate('/login')
    }); // swallow
  }, [navigate, dispatch])

  return (
    <>
      <h1>Logging in...</h1>
    </>
  )
}