import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isLeader, isMember } from "../redux/user";

export default function NavHeader() {
  const leader = useSelector(isLeader)
  const member = useSelector(isMember);

  return (
    <Navbar variant="dark" bg="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          Affordable Care
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {member ? <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/payouts">
              Payouts
            </Nav.Link>
            {leader ? <NavDropdown title="Admin" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/admin/kills">
                Review Deaths
              </NavDropdown.Item>
            </NavDropdown>
            : null}
          </Nav>
        </Navbar.Collapse> : null }
      </Container>
    </Navbar>
  );
}
