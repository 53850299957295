import { configureStore } from "@reduxjs/toolkit";
import flash from "./flash";
import user from "./user";

const store = configureStore({
  reducer: {
    flash: flash,
    user: user,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
