import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { Notification } from '../types';

// Define a type for the slice state
interface FlashState {
  notifications: Notification[];
}

// Define the initial state using that type
const initialState = {
  notifications: []
} as FlashState

export const flashSlice = createSlice({
  name: 'flash',
  initialState,
  reducers: {
    addNotification: (state: FlashState, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload)
    },
    removeNotification: (state: FlashState, action: PayloadAction<Notification>) => {
      state.notifications = state.notifications.filter(n => action.payload === n)
    }
  },
})

export const { addNotification, removeNotification } = flashSlice.actions

export const getNotifications = (state: RootState) => state.flash.notifications

export default flashSlice.reducer