//@ts-ignore
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import { AppDispatch } from "./redux/store";
import { addNotification } from "./redux/flash";

export function hasSession(): boolean {
  const loginToken = Cookies.get("session");
  return !!loginToken;
}

export function logout() {
  Cookies.remove("session");
}

export function doFetch(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  return fetch(input, init).then((resp) => {
    let [first, second] = resp.body!.tee();

    if (!resp.ok) {
      // Submit all 5xx to sentry
      if (resp.status <= 500) {
        const tapped = new Response(first);
        tapped.text().then((data) => {
          Sentry.withScope(function (scope) {
            scope.setTag("response-text", data);
            scope.setTag("response-status", resp.status);
            Sentry.captureException(new Error(`invalid response`));
          });
        });
      }

      // logged out
      if (resp.status === 401) {
        document.location.replace("/login");
      }
    }

    // No content
    if (resp.status === 204) {
      // @ts-ignore
      second = null;
    }

    return new Response(second, {
      headers: resp.headers,
      status: resp.status,
      statusText: resp.statusText,
    });
  });
}

export const DateTimeDisplayOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: false,
  timeZone: "UTC",
};

export function formatTime(timeStr: string): string {
  return new Intl.DateTimeFormat("default", DateTimeDisplayOptions).format(
    new Date(timeStr)
  );
}

export function formatCurrency(value: number): string {
  return `${new Intl.NumberFormat("en-US").format(value)} ISK`;
}

export function clearRole() {
  localStorage.removeItem("user_role");
}

export function getRole(): string | null {
  return localStorage.getItem("user_role");
}

export function setRole(role: string) {
  localStorage.setItem("user_role", role);
}

export function savePayout(
  ship_type: number,
  base_amount: number,
  id: number | null | undefined,
  dispatch: AppDispatch
) {
  const url = !!id ? `/api/admin/payouts/${id}` : `/api/admin/payouts`;

  doFetch(url, {
    method: !!id ? "PATCH" : "POST",
    body: JSON.stringify({
      ship_type_id: ship_type,
      base_amount: base_amount,
    }),
  }).then((resp) => {
    if (!resp.ok) {
      if (resp.status === 422) {
        dispatch(
          addNotification({
            type: "danger",
            text: "Invalid values. Does ship type already exist for payouts?",
            dismissTime: 2,
          })
        );
      } else {
        dispatch(
          addNotification({
            type: "danger",
            text: "Unable to save payout.",
            dismissTime: 2,
          })
        );
      }

      throw new Error(`invalid payout response ${resp.status}`);
    }

    document.location.reload();
  });
}
